import React, { useState, useEffect } from "react"
import ProjectDetailPage from "../../../pages/metrics/project-detail"
import { Loading } from "../../../components/loading"
import { useAuth0 } from "@auth0/auth0-react"
import { useUserOrgs } from "../../../hooks/use-user"
import { fetch_get } from "../../../utils/fetch"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/header/header"
import MetricsProjectDetail from "../../../components/metrics/projects/metrics-project-detail"
import PermissionDenied from "../../../components/common/permissionDenied"

const OpmContainer = props => {
  const { id, parentOrganization, location } = props
  const [isOperate, setisOperate] = useState(false)
  const [isReady, setisReady] = useState(false)
  const [roles, setRoles] = useState([])
  const [projParentOrgName, setprojParentOrgName] = useState(null)

  const { getAccessTokenSilently, isLoading, error, user } = useAuth0()
  const {
    loading,
    currentOrg: {
      index,
      name,
      role: { isSystemAdmin, isSuperAdmin, isProjectAdmin },
    },
  } = useUserOrgs()
  var corg = useUserOrgs()
  const isUserOrgAndProjectOrgTheSame = () => {
    // console.log("corg", corg)
    var qc = ""
    if (corg.currentOrg.name !== null) {
      qc =
        corg.currentOrg.name.toLowerCase() == "quic, inc."
          ? "clo"
          : corg.currentOrg.name.toLowerCase()
    }
    var x =
      props.parentOrganization.toLowerCase() === qc ||
      (projParentOrgName !== null && projParentOrgName === qc)
    // console.log(
    //   x,
    //   projParentOrgName,
    //   props,
    //   props.parentOrganization.toLowerCase(),
    //   corg.currentOrg.name.toLowerCase()
    // )
    return x
  }

  function getAdditionalUrlParams() {
    var urlparams = ""
    if (isSuperAdmin || props.path === "/projects/:parentOrganization/:id") {
      urlparams = `?parentOrganization=${props.parentOrganization}`
    } else {
      urlparams = `?index=${index}`
    }
    return urlparams
  }
  const checkIfUserIsAController = () => {
    // if (roles === undefined || roles === null) return false
    let found = roles.filter(function (item) {
      return user.nickname === item.username
    })
    return found.length === 1
  }

  const getProjectRole = async () => {
    return new Promise(async (resolve, reject) => {
      var urlparams = getAdditionalUrlParams()
      var token = await getAccessTokenSilently()
      // console.log(`projects/${props.id}${urlparams}`, props)
      fetch_get(`projects/${props.id}${urlparams}`, token)
        .then(response => {
          // setControllersData(response.roles.controllers)
          var val = []
          setprojParentOrgName(response.parentOrganization.toLowerCase())
          if (response.roles !== undefined && response.roles.controllers !== undefined)
            val = response.roles.controllers
          resolve(val)
        })
        .catch(() => {
          // setControllersData([])
          resolve([])
        })
    })
  }

  const fillroles = async () => {
    var roles = await getProjectRole()
    setRoles(roles)
    setisReady(true)
  }

  // useEffect(() => {
  //   if (roles.length === 0) return
  //   console.log(isOperate, isSuperAdmin, isSystemAdmin, isProjectAdmin, checkIfUserIsAController())
  //   console.log(
  //     isReady ||
  //       isSuperAdmin ||
  //       isSystemAdmin ||
  //       isProjectAdmin ||
  //       isOperate ||
  //       checkIfUserIsAController()
  //   )
  // }, [roles])

  useEffect(() => {
    if (isOperate === undefined || isOperate === null) return
    if (isSuperAdmin === undefined || isSuperAdmin === null) return
    if (isSystemAdmin === undefined || isSystemAdmin === null) return
    if (isProjectAdmin === undefined || isProjectAdmin === null) return

    fillroles()
  }, [isOperate, isSuperAdmin, isSystemAdmin, isProjectAdmin])

  useEffect(() => {
    if (user === undefined || user === null || name === null) return
    // const val = (user["https://api.codelinaro.org/single-project-admin"]?.find(p=>p.parentOrganization === name)?.projects||[]).find(
    //   p => p.slug === props.id
    // )
    const val = user["https://api.codelinaro.org/single-project-admin"]
      ?.find(p => p.parentOrganization === name)
      ?.projects
      ?.find(p => p.slug === props.id) ?? null;

    setisOperate(val)
  }, [user, name])

  return (
    <>
      {isReady &&
        (isSuperAdmin ||
          ((isSystemAdmin || isProjectAdmin || isOperate || checkIfUserIsAController()) &&
            isUserOrgAndProjectOrgTheSame())) && (
          <>
            <MetricsProjectDetail
              projectId={id}
              parentOrganization={parentOrganization}
              orgIndex={index}
              location={location}
            />
          </>
        )}
      {isReady &&
        !(
          isSuperAdmin ||
          ((isSystemAdmin || isProjectAdmin || isOperate || checkIfUserIsAController()) &&
            isUserOrgAndProjectOrgTheSame())
        ) && (
          <>
            <PermissionDenied />
          </>
        )}
    </>
  )
}

export default OpmContainer
