import React, { useEffect, useState } from "react"
import { Router } from "@reach/router"
import ErrorPage from "../../../../404"
import { SystemAdminRoute, ProjectMetricsRoute } from "../../../../../components/admin-route"
// import ProjectDetailPage from "../../../../metrics/project-detail"
import OpmContainer from "../../../../../components/metrics/organizationProjectMetrics/opmContainer"
import { Loading } from "../../../../../components/loading"
import { Error } from "../../../../../components/error"
import { useAuth0 } from "@auth0/auth0-react"
import ProtectedRoute from "../../../../../components/protected-route"
import { fetch_get } from "../../../../../utils/fetch"

const Ometrics = () => {
  const { isLoading, error } = useAuth0()

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {error && <Error message={error.message} />}
      <Router>
        <ProjectMetricsRoute
          path="organization/:parentOrganization/project/:id/metrics"
          component={OpmContainer}
        />

        <ErrorPage default />
      </Router>
    </>
  )
}

export default Ometrics
