import React from "react"
import Layout from "../../components/layout/layout"
import Content from "../../components/content/content"
import Header from "../../components/header/header"

const PermissionDeniedPage = props => {
  return (
    <Layout pageTitle="Permission Denied 1">
      <Header contentPage={true} className="contentPage">
        <h1 className="display-4 font-weight-bold">Permission Denied</h1>
      </Header>
      <Content>
        <p>You have no permission to access this page.</p>
      </Content>
    </Layout>
  )
}

export default PermissionDeniedPage
